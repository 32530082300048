import {LogLevel} from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    /* Azure Application(client)ID */
    clientId: "a23ed8d5-2bd9-41da-8964-20ea201f65eb", // DEV
    authority:
      "https://login.microsoftonline.com/fd20b68e-dcde-4bce-a8d9-fb14f8d119fc", // Azure Tenant ID

    /* Local Development */
//     redirectUri: "http://localhost:3000/login",
//     postLogoutRedirectUri: "http://localhost:3000/login",

    /* Development Server */
//    redirectUri: "https://gamechanger-v3-domain-test.icxeed.ai/login",
//    postLogoutRedirectUri: "https://gamechanger-v3-domain-test.icxeed.ai/login",

    /* Production Server */
    redirectUri: "https://gamechanger.icxeed.ai/login",
     postLogoutRedirectUri: "https://gamechanger.icxeed.ai/login",
  },

  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    "User.Read",
    "User.ReadBasic.All",
    "User.Read.All",
    "User.ReadWrite",
    "User.ReadWrite.All",
    "User.ManageIdentities.All",
    "User.EnableDisableAccount.All",
    "Directory.ReadWrite.All",
  ],
};
